@import "app/styles/screens";

// 1025px and wider
@mixin desktop {
  @media (min-width: calc($screen-md + 1px)) {
    @content;
  }
}

//  1280px and wider
@mixin desktopLg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

// 1025px - 1279px
@mixin desktopSm {
  @media (min-width: calc($screen-md + 1px)) and (max-width: calc($screen-lg - 1px)) {
    @content;
  }
}

// 980px - 1024px
@mixin tablet {
  @media (min-width: 980px) and (max-width: $screen-md) {
    @content;
  }
}

// 0px - 979px
@mixin phone {
  @media (max-width: 979px) {
    @content;
  }
}

// 768px - 1023px
@mixin tabletSmall {
  @media (min-width: $screen-sm) and (max-width: calc($screen-md - 1px)) {
    @content;
  }
}

// 0px - 767px
@mixin phoneSmall {
  @media (max-width: calc($screen-sm - 1px)) {
    @content;
  }
}
